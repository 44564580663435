import { randomId } from '@landing/lib/utils'
import { writable } from 'svelte/store'

function createToasts () {
    const { subscribe, set, update } = writable(new Map())

    function add (type, message, timeout = null) {
        update(
            (toasts) => {
                const id = randomId()
                toasts.set(id, { id, type, message, timeout })

                if (timeout) {
                    setTimeout(() => remove(id), timeout)
                }

                return toasts
            },
        )
    }

    function remove (id) {
        update(
            (toasts) => {
                toasts.delete(id)
                return toasts
            },
        )
    }

    function reset () {
        set(new Map())
    }

    return {
        subscribe,
        add,
        remove,
        reset,
    }
}

export const toasts = createToasts()
