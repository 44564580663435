export function preloadImage (src) {
    return new Promise(function (resolve) {
        const img = new Image()
        img.onload = resolve
        img.src = src
    })
}

export function randomId () {
    return Math.random().toString(36).substr(2, 9)
}

export function scale (start = 0, stop = 10, step = 1) {
    const result = []
    let value = start

    while (value <= stop) {
        result.push(value)
        value += step
    }

    return result
}
